import React, {useState} from 'react';
import {Collapse, Tooltip} from '@mui/material';
import classNames from 'classnames';
import {TransitionGroup} from 'react-transition-group';
import {PERIODS, PERIODS_RU} from '../../../../../modules/Periods';
import {RATING_GROUPS} from '../../../../../modules/Ratings';
import SuggestionsPopupContainer from './SuggestionsPopupContainer';
import EmptyBondSuggestion from './EmptyBondSuggestion';
import BondSuggestion from './BondSuggestion';
import {RatingTooltip, RatingTooltipTitle} from './RatingTooltip';
import homeStyles from '../styles.module.sass';
import styles from './styles.module.sass';
import SkeletonBondSuggestion from "./SkeletonBondSuggestion";

export const Suggestions = ({data, colorScale, measuredValue, isFilterView}) => {
  const [popup, setPopup] = useState({});

  const onSuggestionClick = (element, rating, period) => {
    if (popup && popup.rating === rating && popup.period === period) {
      setPopup({});
    } else {
      setPopup({element, rating, period});
    }
  };

  return (
    <>
      {data && !!Object.keys(data).length && (
        <>
          <div className={styles.periodAxis}>
            {PERIODS_RU
              // .filter((period, i) => Object.keys(data[Object.keys(data)[0]]).includes(PERIODS[i]))
              .map((period, i) => (
                <Tooltip
                  key={period}
                  title={
                    <div className={homeStyles.tooltipContent}>
                      <div className={homeStyles.tooltipText}>
                        Показываем варианты облигаций в сравнении с банковским депозитом за указанный срок
                      </div>
                    </div>
                  }
                >
                  <div className={classNames(
                    styles.periodTitle,
                    Object.keys(data[Object.keys(data)[0]]).includes(PERIODS[i])?'':styles.periodDisabled
                  )}>
                    {period}
                  </div>
                </Tooltip>
              ))}
          </div>
          <TransitionGroup>
            {Object.keys(RATING_GROUPS).reverse().map((rating, i) => data[rating] && (
              <Collapse key={rating}>
                <div className={styles.ratingRowContainer}>
                  <div className={styles.ratingRow}>
                    <RatingTooltip title={<RatingTooltipTitle rating={rating} />} placement="right">
                      <div className={classNames(styles.ratingAxisTick, `bgRating${rating.replace('+', 'P')}`)}>
                        <div className={classNames(styles.ratingTitle)}>

                        </div>
                      </div>
                    </RatingTooltip>
                    <div className={styles.suggestionsRow}>
                      {PERIODS.map((period, j) => {
                        if (!data[rating][period]) {
                          return <SkeletonBondSuggestion key={period} />
                          // return null;
                        }
                        if (data[rating][period].length === 0) {
                          return <EmptyBondSuggestion key={period} />
                        }
                        const bond = data[rating][period][0];
                        return <BondSuggestion
                          key={period}
                          data={data}
                          bond={bond}
                          colorScale={colorScale}
                          period={period}
                          measuredValue={measuredValue}
                          rating={rating}
                          popup={popup}
                          onSuggestionClick={onSuggestionClick}
                          isFilterView={isFilterView}
                      />
                      })}
                    </div>
                  </div>
                  <TransitionGroup>
                    {popup && popup.rating && popup.period && rating === popup.rating && data[popup.rating][popup.period] && (
                      <Collapse>
                        <SuggestionsPopupContainer
                          colorScale={colorScale}
                          parentElement={popup.element}
                          bonds={data[popup.rating][popup.period]}
                          onClose={() => setPopup({})}
                          measuredValue={measuredValue}
                        />
                      </Collapse>
                    )}
                  </TransitionGroup>
                </div>
              </Collapse>
            ))}
          </TransitionGroup>
        </>
      )}
    </>
  );
};
