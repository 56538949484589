import {RATINGS} from '../../../../../../modules/Ratings';
import styles from './RatingChart.module.sass';

export const RatingChart = () => {
  return (
    <div>
      <div className={styles.description}>
        <span>Высокий риск</span>
        <span>Низкий риск</span>
      </div>
      <div className={styles.columns}>
        {RATINGS.map((rating) => (
          <div key={rating} className={styles.column}>
            <div className={styles.value}></div>
          </div>
        ))}
      </div>
    </div>
  );
};
