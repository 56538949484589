import React from 'react';
import classNames from 'classnames';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {getRatingStatus, getRatingStatusKey, RATINGS} from '../../../../../modules/Ratings';
import styles from './styles.module.sass';
import ratingTooltipStyles from './RatingTooltip.module.sass';
import ratingChartStyles from '../Filter/Charts/RatingChart.module.sass';

const RatingChart = ({ratings, getHeight}) => {
  return (
    <div className={ratingTooltipStyles.columns}>
      {ratings.map((rating, i) => (
        <div
          key={rating}
          className={classNames(ratingTooltipStyles.column, `bgRating${rating.replace('+', 'P')}`)}
          style={{height: `${getHeight(i)}%`}}
        >
          <div className={classNames(ratingChartStyles.value, ratingTooltipStyles.value)}></div>
        </div>
      ))}
    </div>
  );
};

export const RatingTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const RatingTooltipTitle = ({rating}) => {
  const isLowRisk = getRatingStatusKey([rating]) === 'low';

  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipH1}>{getRatingStatus([rating])}</div>
      <RatingChart
        ratings={isLowRisk ? RATINGS.slice(4, 8) : RATINGS.slice(0, 4).reverse()}
        getHeight={(i) => isLowRisk ? (50 + i * 10) : (80 - i * 10)}
      />
      <div className={styles.tooltipText}>
        Это облигации с рейтингом надежности <span className={styles.tooltipBold}>{isLowRisk ? 'BBB+' : 'BBB'}</span> и {isLowRisk ? 'выше' : 'ниже'}.
        <br /><br />
        {isLowRisk ?
          'Такие облигации по закону могут покупать пенсионные фонды, чтобы сохранить и приумножить пенсионные отчисления.' :
          (<>
            Облигации с недостаточно высоким уровнем надежности.
            <br />
            Только для опытных инвесторов.
          </>)}
      </div>
      <div className={styles.tooltipH2}>Подсказка</div>
      <div className={styles.tooltipText}>
        Среди одинаковых по сроку и доходности облигаций,
        <br />
        лучше выбирать те, у которых выше рейтинг надежности.
        <br /><br />
        Например, <span className={styles.tooltipBold}>АА</span> считается надежнее, чем <span className={styles.tooltipBold}>A</span>.
      </div>
    </div>
  );
};
