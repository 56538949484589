import React, {useMemo, useRef} from 'react';
import {Tooltip} from '@mui/material';
import classNames from 'classnames';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatAmount} from '../../../../../modules/format';
import {pluralize} from '../../../../../modules/pluralize';
import {PERIODS} from '../../../../../modules/Periods';
import {RATINGS} from '../../../../../modules/Ratings';
import {Amount} from '../../../common/Amount/Amount';
import styles from './BondSuggestion.module.sass';
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";

/** NB: используется для отлова событий */
export const SUGGESTION_CONTAINER_CLASSNAME = 'suggestionContainer';

const BondSuggestion = ({data, bond, colorScale, period, rating, popup, measuredValue, onSuggestionClick, isFilterView}) => {
  const wrapperRef = useRef(null);
  const suggestionsLength = data[rating][period].length;
  const isActive = useMemo(
    () => popup && popup.rating === rating && popup.period === period,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, rating, period, popup]
  );
  const isUnprofitable = useMemo(() => {
    if (!isFilterView) {
      return false;
    }
    const periodIndex = PERIODS.indexOf(period);
    const ratingIndex = RATINGS.indexOf(rating);
    const peerRating = RATINGS[ratingIndex + 1];
    const peerPeriod = PERIODS[periodIndex - 1];
    // console.log(bond)
    const compareValue = measuredValue//'yield';
    return (data[peerRating] && data[peerRating][period] && data[peerRating][period].length && data[peerRating][period][0][compareValue] > bond[compareValue])
      ||
    (data[rating] && data[rating][peerPeriod] && data[rating][peerPeriod].length && data[rating][peerPeriod][0][compareValue] > bond[compareValue])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, measuredValue, isFilterView]);

  const NodeUnit = useMemo(() => {
    if (isUnprofitable) {
      return (({children}) => (
          <Tooltip
            enterDelay={500}
            enterNextDelay={500}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -50],
                  },
                },
              ],
            }}
            title={(
              <div className={styles.isUnprofitableTooltip}>
                Компании в этой карточке приносят меньший доход, несмотря на более высокий риск или длительный срок погашения. Левее или ниже есть предложения лучше
              </div>
            )}>
              {children}
          </Tooltip>
        )
      )
    }
    return (({children}) => <div>{children}</div>);
  }, [isUnprofitable]);

  return (
    <div className={classNames(SUGGESTION_CONTAINER_CLASSNAME, styles.suggestionContainer, {
      [styles.__active]: isActive,
    })}>
      <NodeUnit>
        <div
          key={period}
          style={{background: colorScale(bond[measuredValue])}}
          className={classNames(
            styles.suggestion,
            {
              [styles.__active]: isActive,
              [styles.__unprofitable]: isUnprofitable,
            }
          )}
          onClick={() => onSuggestionClick(wrapperRef.current, rating, period)}
          ref={wrapperRef}
        >
          <div>
            Заработаю
          </div>
          <div className={styles.suggestionDifferenceWithDeposite} title={formatAmount(bond.income, false, ' ₽')}>
            <Amount value={bond.income} />
          </div>
          <div>
            Доходность
          </div>
          <div className={styles.suggestionIncome} title={formatAmount(bond.yield, false, '%')}>
            {Math.round(bond.yield*100)/100}%
          </div>
          <div>
            Выгоднее депозита на
          </div>
          <div className={styles.suggestionIncome} title={formatAmount(bond.differenceWithDeposit, false, ' ₽')}>
            <Amount value={bond.differenceWithDeposit} />
          </div>
          <div className={styles.suggestionBonds}>
            {suggestionsLength + ' ' + pluralize(suggestionsLength, ['облигация', 'облигации', 'облигаций'])}
            <span>
              {isActive
                ? <ExpandLessIcon fontSize="small" />
                : <ExpandMoreIcon fontSize="small" />
              }
            </span>
          </div>
         </div>
      </NodeUnit>
    </div>
  );
};

export default BondSuggestion;
